import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppWrapper from '../components/templates/AppWrapper';
import Home from '../pages/Home';
import Legal from '../pages/Legal';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <AppWrapper>
                <Routes>
                    <Route
                        index
                        element={<Home />}
                    />
                    <Route
                        path='legal'
                        element={<Legal />}
                    />
                </Routes>
            </AppWrapper>
        </BrowserRouter>
    );
};

export default AppRoutes;
