import React from 'react';
import Icon from '../atoms/Icon';

const Navigation = ({ className }) => {
    const nav = [
        { title: 'Home', path: '/' },
        { title: 'About Us', path: '/#about' },
        { title: 'Market', path: '/#market' },
        { title: 'Partners', path: '/#partners' },
        { title: 'Values', path: '/#values' },
        { title: 'Legal', path: '/legal' },
    ];

    return (
        <nav
            role='navigation'
            className={className}>
            {/* <span
                className='ic menu'
                tabIndex='1'>
                <span className='line'></span>
                <span className='line'></span>
                <span className='line'></span>
            </span>
            <span className='ic close'></span> */}
            <ul className='nav'>
                {nav.map((item) => {
                    return (
                        <li
                            className='nav-item'
                            key={item.title}>
                            <a
                                target={item.target}
                                href={item.path}
                                className={`
                                    ${item.children ? 'mega-menu' : ''}
                                    ${item.btn ? 'btn btn-white' : ''}
                                `}>
                                {item.title}
                                {item.icon && <Icon icon={item.icon} />}
                                {item.children && item.children.length > 0 && (
                                    <Icon icon='arrow-down-s-line' />
                                )}
                            </a>
                            {item.children && item.children.length > 0 && (
                                <div className='nav__dropdown'>
                                    {item.children && (
                                        <div className='row'>
                                            {item.children.map((subgroup) => {
                                                return (
                                                    <div
                                                        key={subgroup.title}
                                                        className='col'>
                                                        <h2 className='nav-head'>
                                                            {subgroup.title}
                                                        </h2>
                                                        <ul className='nav__dropdown__list'>
                                                            {subgroup.children.map((subitem) => {
                                                                return (
                                                                    <li key={subitem.title}>
                                                                        <a href={subitem.path}>
                                                                            {subitem.title}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Navigation;
