import React, { useEffect, useState } from 'react';
import Logo from '../atoms/Logo';
import Navigation from '../molecules/Navigation';
import Container from '../atoms/Container';
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 50) {
            // Change 100 to your desired scroll threshold
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header
            id='header'
            className={`header ${isScrolled ? 'scrolled' : ''}`}>
            <Container size='unpadded'>
                <div className='header__inner'>
                    <Logo />
                    <Navigation />
                    <a
                        href='#contact'
                        className='btn btn-xs'>
                        Contact Us
                    </a>
                </div>
            </Container>
        </header>
    );
};
export default Header;
