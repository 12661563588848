import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import Cards from '../components/organisms/Cards';

const Home = () => {
    return (
        <>
            <div
                className='static_banner static_banner_singapore'
                id='home'>
                <div className='static_banner__inner'>
                    <div className='static_banner__inner__content'>
                        <div className='static_banner__inner__credit'>
                            Image from{' '}
                            <a
                                rel='noreferrer'
                                target='_blank'
                                href='https://www.freepik.com/free-photo/singapore-cityscape-twilight_11769039.htm'>
                                Freepik
                            </a>
                        </div>
                        <div className='hero-text'>
                            <h1>
                                Investing in <br />
                                <span className='circled'>Private</span> Credit
                            </h1>
                            <h4>Transforming Businesses Across Southeast Asia (SEA)</h4>
                            <Container size='unpadded'>
                                <p>
                                    Blue Ashva Singapore is an alternative credit company that
                                    provides bespoke credit financing solutions to sustainable and
                                    scalable businesses operating at the intersection of
                                    cutting-edge technologies and customer satisfaction. We are a
                                    sector-agnostic investor and strive to be a valuable alt-credit
                                    funding partner for new economy early-to-growth-stage companies
                                    and SMEs across Southeast Asia.
                                </p>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>

            <Container
                className='text-center'
                id='about'>
                <Heading>About Us</Heading>
                <Paragraph>
                    As an investment management company, Blue Ashva Singapore aims to partner with
                    exceptional entrepreneurs to solve real world problems and build sustainable
                    businesses in the Southeast Asia region. With over five decades of combined
                    experience in managing credit businesses, our team is well-equipped to
                    understand the debt funding requirements of high-growth companies and provide
                    customised credit solutions for them.
                </Paragraph>
            </Container>

            <Container
                id='market'
                size='lg'
                className='text-center'>
                <Heading>
                    Market <span className='underlined'>Opportunities!</span>
                </Heading>
                <div className='numbers'>
                    <div className='numbers__item'>
                        <div className='numbers__item__icon'>
                            <i className='ri-team-line'></i>
                        </div>
                        <div className='numbers__item__value'>685M</div>
                        <div className='numbers__item__label'>Population</div>
                    </div>
                    <div className='numbers__item'>
                        <div className='numbers__item__icon'>
                            <i className='ri-currency-line'></i>
                        </div>
                        <div className='numbers__item__value'>$3.7T</div>
                        <div className='numbers__item__label'>GDP</div>
                    </div>
                    <div className='numbers__item'>
                        <div className='numbers__item__icon'>
                            <i className='ri-line-chart-line'></i>
                        </div>
                        <div className='numbers__item__value'>5%</div>
                        <div className='numbers__item__label'>GDP Growth Rate</div>
                    </div>
                    <div className='numbers__item'>
                        <div className='numbers__item__icon'>
                            <i className='ri-smartphone-line'></i>
                        </div>
                        <div className='numbers__item__value'>$1T</div>
                        <div className='numbers__item__label'>Digital Economy by 2030</div>
                    </div>
                </div>
            </Container>

            <Container
                id='partners'
                size='lg'
                className='text-center'>
                <Heading>Meet our Partners</Heading> <br />
                <Cards
                    config={{
                        className: 'no-border',
                        colClass: 'col-4 col-xs-12',
                        items: [
                            {
                                img: '/img/team/satya.png',
                                imgClass: 'cards__item__img-circle',
                                heading: 'Satya Bansal',
                                desc: 'Founder of Blue Ashva Group',
                                link: {
                                    text: 'View Profile',
                                    href: 'https://www.linkedin.com/in/satyabansal/',
                                },
                            },
                            {
                                img: '/img/team/varun.png',
                                imgClass: 'cards__item__img-circle',
                                heading: 'Varun Gupta',
                                desc: 'Executive Director',
                                link: {
                                    text: 'View Profile',
                                    href: 'https://www.linkedin.com/in/varunkumargupta/',
                                },
                            },
                        ],
                    }}
                />
            </Container>

            <Container
                id='values'
                size='unpadded'>
                <div className='text-center'>
                    <Heading align='center'>Values</Heading>
                </div>
                <br />
                <br />
                <div className='values'>
                    <div className='values__item'>
                        <div className='values__item__heading'>
                            <div className='values__item__heading__text'>
                                Fiduciary <br />
                                Responsibility
                            </div>
                            <div className='values__item__heading__icon happy'></div>
                        </div>
                        <div className='values__item__body'>
                            We act as trustees for our stakeholders and are firm in our
                            accountability for the resources we use and the systems and processes we
                            deploy.
                        </div>
                    </div>
                    <div className='values__item'>
                        <div className='values__item__heading'>
                            <div className='values__item__heading__text'>
                                Entrepreneur <br />
                                Centric
                            </div>
                            <div className='values__item__heading__icon heart'></div>
                        </div>
                        <div className='values__item__body'>
                            Recognising the value of entrepreneurship and aligning with their unique
                            needs and aspirations.
                        </div>
                    </div>
                    <div className='values__item'>
                        <div className='values__item__heading'>
                            <div className='values__item__heading__text'>
                                Collaboration <br />
                            </div>
                            <div className='values__item__heading__icon hands'></div>
                        </div>
                        <div className='values__item__body'>
                            We look for opportunities to collaborate with organisations that share
                            our values and drive meaningful impact. Our collective strength brings
                            positive change.
                        </div>
                    </div>
                </div>
                <div className='values'>
                    <div className='values__item'>
                        <div className='values__item__heading'>
                            <div className='values__item__heading__text'>
                                People <br />
                                First
                            </div>
                            <div className='values__item__heading__icon people'></div>
                        </div>
                        <div className='values__item__body'>
                            We prioritise supporting businesses that contribute to the people and
                            society.
                        </div>
                    </div>
                    <div className='values__item'>
                        <div className='values__item__heading'>
                            <div className='values__item__heading__text'>Transparency</div>
                            <div className='values__item__heading__icon trans'></div>
                        </div>
                        <div className='values__item__body'>
                            We maintain transparency and fairness in our operations and follow
                            robust governance practices.
                        </div>
                    </div>
                    <div className='values__item'>
                        <div className='values__item__heading'>
                            <div className='values__item__heading__text'>
                                Environmental <br />
                                Sustainability
                            </div>
                            <div className='values__item__heading__icon home'></div>
                        </div>
                        <div className='values__item__body'>
                            We are committed to safeguarding the environment through responsible
                            investing and integrating sustainability through our efforts.
                        </div>
                    </div>
                </div>
            </Container>
            <Container
                id='contact'
                size='unpadded'>
                <div className='contact'>
                    <div className='contact__section contact__section-map'></div>
                    <div className='contact__section contact__section-details'>
                        <div className='contact__section-details__body'>
                            <Heading type='h3'>Contact Us</Heading>
                            <p>12 MARINA BOULEVARD </p>
                            <p>#17-01 MBFC TOWER 3</p>
                            <p>018982, Singapore</p>

                            <p>
                                <a href='tel:+6568095041'>
                                    <i className='ri ri-phone-fill'></i>
                                    +65 68095041
                                </a>
                            </p>
                        </div>
                        <div className='contact__section-details__footer'>
                            For more information, please get in touch with us at
                            <a href='mailto:contact@blueashvasg.com'>contact@blueashvasg.com</a>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Home;
