import React from 'react';

const Logo = ({ src = '/img/logo.svg' }) => {
    return (
        <a
            href='/'
            className='logo'>
            <img
                className='logo__img'
                alt='Logo'
                src={src}
            />
        </a>
    );
};

export default Logo;
