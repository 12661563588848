import React from 'react';
import StaticBanner from '../components/organisms/StaticBanner';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import { Link } from 'react-router-dom';

const Legal = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Legal</Heading>
                        <h5>Disclaimers relating to the contents of the website</h5>
                    </div>
                </Container>
            </StaticBanner>
            <Container size='md'>
                <Heading type='h4'>TERMS OF USE</Heading>
                <Paragraph className='mb-20'>
                    You should carefully read the following terms and conditions (the "Terms"). The
                    Terms govern your use of this website (this "Site"), including the content,
                    materials and information available on or through this Site. The Site contains
                    general information about Blue Ashva Capital Singapore and is directed at
                    management teams interested in partnering with Blue Ashva Capital Singapore. By
                    using this Site, you acknowledge that you have read, understood and agree to the
                    Terms. If you do not agree to abide by these Terms, do not use this Site.
                </Paragraph>
                <Heading type='h4'>WHO WE ARE</Heading>
                <Paragraph className='mb-20'>
                    Blue Ashva Capital Singapore is a venture capital firm. Its Investment Manager
                    is Blue Ashva Capital Singapore Pte Ltd, which is authorized and regulated by
                    Monetary Authority of Singapore (MAS) and located at 12 Marina Boulevard, #17-01
                    MBFC Tower 3, Singapore 018982.
                </Paragraph>
                <Paragraph className='mb-20'>
                    In accordance with industry custom, this Site occasionally uses terminology that
                    may be interpreted to suggest that our firm is actually a single entity.
                    However, there is no such single entity and all of the entities that comprise
                    our firm have their own separate legal existence. Also, although certain
                    individuals are identified on this Site as "Partners" or "General Partners,"
                    these titles reflect business usage that is customary within our industry and
                    are not intended to indicate that any such individual is actually a partner or
                    general partner of any partnership as those terms are used for legal purposes.
                </Paragraph>
                <Heading type='h4'>
                    PRIVACY, CONFIDENTIAL INFORMATION AND USE OF SITE GENERALLY
                </Heading>
                <Paragraph className='mb-20'>
                    We reserve the right to track visitors to, and usage of, this Site through
                    "cookies" and similar techniques and to use any resulting information as we
                    determine in our sole discretion.
                </Paragraph>
                <Paragraph className='mb-20'>
                    We cannot accept responsibility for protecting against misuse or disclosure of
                    any confidential or proprietary information or other materials in the absence of
                    our express written agreement to do so. Please consider this carefully before
                    sending us any information that you deem confidential or proprietary.
                </Paragraph>
                <Paragraph className='mb-20'>
                    By using the Site, you agree not to: (1) take any action that imposes an
                    unreasonable load on the Site’s infrastructure, (2) use any device, software or
                    routine to interfere or attempt to interfere with the proper working of the Site
                    or any activity being conducted on the Site, (3) attempt to decipher, decompile,
                    disassemble or reverse engineer any of the software comprising or making up the
                    Site, (4) delete or alter any material posted on the Site by Blue Ashva Capital
                    Singapore or any other person or entity, or (5) frame or link to any of the
                    materials or information available on the Site.
                </Paragraph>

                <Heading type='h4'>NO OFFER, SOLICITATION OR ADVICE</Heading>
                <Paragraph className='mb-20'>
                    The purpose of this Site is limited to providing you with general information
                    about us. Nothing on this Site is intended to constitute or forms a part of (i)
                    an offer, or solicitation of an offer, to purchase or sell any security, other
                    asset or service, (ii) investment advice or an offer to provide such advice, or
                    (iii) a basis for making any investment decision. Except as expressly stated by
                    Blue Ashva Capital Singapore in writing, this Site does not make any effort to
                    present a comprehensive or balanced description of Blue Ashva Capital Singapore
                    or its activities.
                </Paragraph>

                <Heading type='h4'>CONTENT</Heading>
                <Paragraph className='mb-20'>
                    This Site contains material, including but not limited to text, graphics, video,
                    audiovisual works, logos, photographs, icons, images, audio clips, and software
                    (collectively referred to as the "Content"). We may own the Content or portions
                    of the Content may be made available to us through arrangements that we have
                    with third-parties. The Content is protected by Singapore and foreign
                    intellectual property laws. Unauthorized use of the Content may result in
                    violation of copyright, trademark, and other laws. You have no rights in or to
                    the Content, and you will not use, copy or display the Content except as
                    permitted under these Terms. You may use Content from the Site solely for your
                    personal use. No other use is permitted without our prior written consent; for
                    example, the Content and Site may not be distributed, republished, uploaded,
                    posted, or transmitted in any way, without our prior written consent. You must
                    retain all copyright and other proprietary notices contained in the original
                    Content on any copy you make of the Content. You may not sell, transfer, assign,
                    license, sublicense, or modify the Content or reproduce, display, publicly
                    perform, make a derivative version of, distribute, or otherwise use the Content
                    in any way for any public or commercial purpose. The use or posting of any of
                    the Content on any other website or in a networked computer environment for any
                    purpose is expressly prohibited. If you violate any part of these Terms, your
                    right to access and/or use the Content and Site will automatically terminate and
                    you will immediately destroy any copies you have made of the Content.
                </Paragraph>
                <Paragraph className='mb-20'>
                    The trademarks, service marks, and logos of Blue Ashva Capital Singapore (the
                    "Blue Ashva Capital Singapore Trademarks") used and displayed on this Site are
                    registered and unregistered trademarks or service marks of Blue Ashva Capital
                    Singapore. Other product and service names located on the Site may be trademarks
                    or service marks owned by third-parties (the "Third-Party Trademarks", and,
                    collectively with the Blue Ashva Capital Singapore Trademarks, the
                    "Trademarks"). Nothing on this Site or in these Terms should be construed as
                    granting, by implication, estoppel, or otherwise, any license or right to use
                    any Trademark displayed on this Site without the prior written consent of Blue
                    Ashva Capital Singapore specific for each such use. The Trademarks may not be
                    used to disparage Blue Ashva Capital Singapore or the applicable third-party or
                    in any manner (using commercially reasonable judgment) that may damage any
                    goodwill in the Trademarks. Use of any Trademarks as part of a link to or from
                    any website is prohibited without our prior written consent. All goodwill
                    generated from the use of any Blue Ashva Capital Singapore Trademark will inure
                    to our benefit.
                </Paragraph>
                <Heading type='h4'>THIRD-PARTY SITES AND INFORMATION</Heading>
                <Paragraph className='mb-20'>
                    As a convenience to you, this Site contains link to websites operated by third
                    parties. When you select certain hyperlinks you will be leaving the Blue Ashva
                    Capital Singapore site. We do not review, control or examine the content of such
                    websites and are not responsible for any third party websites’ content,
                    availability, advertising, products, information, materials or use of your
                    information. You should review the terms of use applicable to those sites. Links
                    to third party websites do not constitute an endorsement of or association with
                    such websites, the contents of the websites, or the owners and/or operators of
                    the websites. Any access to, or use of, a third-party site is solely at your own
                    risk.
                </Paragraph>
                <Paragraph className='mb-20'>
                    You should be aware that a significant portion of the Content includes or
                    consists of information that has been provided by third parties and has not been
                    validated or verified by us. In connection with our activities, we often become
                    subject to a variety of confidentiality obligations to funds, investors,
                    portfolio companies and other third parties. Any statements we make may be
                    affected by those confidentiality obligations, with the result that we may be
                    prohibited from making full disclosures. Without limitation on the effect of
                    other warnings and disclaimers set forth in these Terms, you should interpret
                    any statements we make (on this Site or otherwise) in that context.
                </Paragraph>

                <Heading type='h4'>RESTRICTIONS ON USE; NO LICENSE</Heading>
                <Paragraph className='mb-20'>
                    We reserve all rights with respect to the design, look and feel and content of
                    this Site, such as text, images, photographs, audio and code used to generate
                    the pages of this Site. In particular, you must not misappropriate the design or
                    content of this Site and you must not alter or deface such design or content in
                    any way. Use of the Site does not grant you a license to any content, features
                    or materials you may access on this Site.
                </Paragraph>
                <Heading type='h4'>LIMITATION OF LIABILITY</Heading>
                <Paragraph className='mb-20'>
                    ALL CONTENT IS PROVIDED "AS IS," MAY NOT BE RELIED UPON FOR ANY PURPOSE, AND IS
                    NOT SUBJECT TO EXPRESS OR IMPLIED WARRANTIES OF ANY KIND. In particular, we make
                    no representations or warranties with regard to the Content’s accuracy,
                    completeness, non-infringement or fitness for a particular purpose.
                </Paragraph>
                <Paragraph className='mb-20'>
                    Please ensure that your own computer security is comprehensive and up to date.
                    We accept no responsibility for viruses, malware or other malicious or damaging
                    software contained in the Content or otherwise.
                </Paragraph>
                <Paragraph className='mb-20'>
                    Except as specifically agreed to by Blue Ashva Capital Singapore in writing, no
                    Blue Ashva Capital Singapore entity, any of their affiliates, or any of their
                    respective directors, officers, employees, agents, or content or service
                    providers ("Blue Ashva Capital Singapore Parties") will be liable liability for
                    any direct, indirect, special, incidental, consequential, exemplary or punitive
                    damages arising from, or directly or indirectly related to, your use of, or
                    reliance upon, this Site. Some jurisdictions limit our ability to disclaim
                    liability. With regard to those jurisdictions, our liability shall be limited to
                    the greatest extent permitted by applicable law.
                </Paragraph>

                <Heading type='h4'>INDEMNIFICATION</Heading>
                <Paragraph className='mb-20'>
                    You agree to defend, indemnify, and hold harmless the Blue Ashva Capital
                    Singapore Parties from and against any claims, actions or demands, including,
                    without limitation, reasonable legal and accounting fees, arising or resulting
                    from your breach of these Terms or your access to, use, or misuse of the Content
                    or Site. Blue Ashva Capital Singapore will provide notice to you of any such
                    claim, suit, or proceeding. Blue Ashva Capital Singapore reserves the right to
                    assume the exclusive defense and control of any matter which is subject to
                    indemnification under this provision. In such case, you agree to cooperate with
                    any reasonable requests assisting Blue Ashva Capital Singapore’s defense of such
                    matter.
                </Paragraph>

                <Heading type='h4'>OTHER INFORMATION</Heading>
                <Paragraph className='mb-20'>
                    This Site is operated and controlled by Blue Ashva Capital Singapore in
                    Singapore. If it is illegal or prohibited in your country of origin to access or
                    use this Site, then you should not do so. Those who choose to access this Site
                    outside Singapore access it on their own initiative and are responsible for
                    compliance with all applicable local laws and regulations.
                </Paragraph>
                <Paragraph className='mb-20'>
                    These Terms, and any disputes relating to these Terms or your use of this Site,
                    will be governed in all respects by the laws of Singapore, without regard to
                    conflicts of laws principles. Except as otherwise agreed in writing by the
                    applicable Blue Ashva Capital Singapore entities, any disputes relating to these
                    Terms shall be resolved exclusively in the courts located in Singapore. These
                    Terms set forth the entire agreement between you and us with respect to the
                    subject matter hereof and supersede all prior agreements relating to such
                    subject matter. All Blue Ashva Capital Singapore individuals and entities are
                    intended third-party beneficiaries of these Terms. Our rights under these Terms
                    may be waived by us only in writing. These Terms are binding on you as well as
                    your successors and permitted assigns. In the event any provision of these Terms
                    is determined to be invalid or unenforceable, such provision shall be deemed
                    severed from the remainder of these Terms and replaced with a valid and
                    enforceable provision as similar in intent as reasonably possible to the
                    provision so severed, and shall not cause the invalidity or unenforceability of
                    the remainder of these Terms.
                </Paragraph>
                <Paragraph className='mb-20'>
                    We reserve the right to modify these Terms at any time. If we do this, we will
                    post the changes on this page and will indicate at the bottom of this page the
                    date the Terms were last revised. You may read a current, effective copy of the
                    Terms at any time by selecting the "Legal" link on the Site. You should check
                    these Terms periodically for changes. Your continued use of this Site after the
                    posting of changes constitutes your binding acceptance of such changes.
                </Paragraph>
            </Container>
        </>
    );
};

export default Legal;
