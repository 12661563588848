import React from 'react';
import Navigation from '../molecules/Navigation';
import Container from '../atoms/Container';

const Footer = () => {
    return (
        <Container size='unpadded'>
            <footer className='footer'>
                <p>&copy; Blue Ashva Singapore</p>
                <Navigation className='footer__nav' />
                <div className='social'>
                    <a
                        href='https://www.linkedin.com/company/blue-ashva-capital-singapore/?viewAsMember=true'
                        target='_blank'
                        className='social__item'>
                        <i className='ri-linkedin-box-fill'></i>
                    </a>
                </div>
            </footer>
        </Container>
    );
};

export default Footer;
